/* FONTS */
@font-face {
    font-family: Centra;
    src: url('./assets/font/CentraNo2-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: Centra;
    src: url('./assets/font/CentraNo2-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: Centra;
    src: url('./assets/font/CentraNo2-Book.ttf');
    font-weight: 400;
}

/* DEFAULT CSS */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: 75px;
}

body {
    font-weight: 400;
    overflow-x: hidden;
    position: relative;
    background-color: #121212 !important;
    color: #fff !important;
    font-family: 'Centra', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    line-height: normal;
}

p,
a,
li,
button,
ul {
    margin: 0;
    padding: 0;
    line-height: normal;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

img {
    width: 100%;
    height: auto;
}

button {
    border: 0;
    background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
}

@media (min-width:1700px) {
    main .container {
        max-width: 100%;
        padding: 0 150px;
    }
}

p.success {
    color: green;
}

p.danger {
    color: red;
}

/* NAVBAR */

nav.navbar {
    padding: 18px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
    transition: 0.32s ease-in-out;
}

nav.navbar.scrolled {
    padding: 0px 0;
    background-color: #121212;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
    font-weight: 400;
    color: #fff !important;
    letter-spacing: 0.8px;
    padding: 26px 26px;
    font-size: 18px;
    opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}

.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
    transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
    transform: scale(1);
}

.social-icon a img {
    width: 60%;
    z-index: 1;
    transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
    filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
    outline: none;
    box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
    width: 24px;
    height: 17px;
    background-image: none;
    position: relative;
    border-bottom: 2px solid #fff;
    transition: all 300ms linear;
    top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
    border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
    width: 24px;
    position: absolute;
    height: 2px;
    background-color: #fff;
    top: 0;
    left: 0;
    content: '';
    z-index: 2;
    transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
    top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
    transform: rotate(45deg);
    background-color: #fff;
    height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
    transform: translateY(8px) rotate(-45deg);
    background-color: #fff;
    height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    border-color: transparent;
}

@media only screen and (max-width: 767px) {

    .social-icon a img {
        width: 40%;
    }
}

/* BANNER */
.banner {
    height: 100vh;
    background: linear-gradient(rgba(3, 5, 14, 0.5) 20%, rgba(20, 80, 98, 0.7), rgba(3, 5, 14, 0.5));
    padding: 18vh 3vw;
    display: flex;
    justify-content: center;
    text-align: center;
}

.banner-left-column {
    width: 65%;
    margin-top: 2%;
    padding: 0 5%;
}

.banner-right-column {
    width: 35%;
    margin-top: 2%;
    margin-left: -4%;
    padding-right: 10%;
}

.banner .tagline-box {
    width: 38vw;
    height: 6vh;
    margin-bottom: 4%;
    background: linear-gradient(90deg, rgba(248, 21, 21, 0.5) -6%, rgba(74, 47, 189, 0.5) 110%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 15px;
    font-weight: bolder;
    letter-spacing: .1rem;
    font-size: 2rem;
    display: inline-block;
    /* Gradient Welcome */
    /* background: -webkit-linear-gradient(rgb(246, 75, 1), rgba(12, 207, 81, 0.682));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}

.banner h1 {
    font-size: 4rem;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1;
    margin-bottom: 4%;
    display: block;
}

.banner h2 {
    margin-bottom: 2%;
}

.banner p {
    color: #B8B8B8;
    font-size: 1.2rem;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin-bottom: 6%;
}

.banner-connect-btn {
    min-height: 3rem;
    margin-top: 4%;
}

.banner-discover-btn {
    min-height: 3rem;
}

.banner .button {
    min-width: 13rem;
    min-height: 3rem;
    font-weight: 500;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5%;
    text-decoration: none;
    padding: 2% 3%;
    font-size: 1.2rem;
    position: relative;
    background-color: rgba(248, 21, 21, 0.5);
    transition: 0.4s ease-in-out;
}

.banner .button span {
    z-index: 1;
}

.banner .button::before {
    content: "";
    width: 0%;
    height: 100%;
    position: absolute;
    background-color: white;
    left: 0;
    top: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}

.banner .button:hover {
    color: #121212;
}

.banner .button:hover::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
}

.banner img {
    border: 2px solid grey;
    border-radius: 5%;
    width: 19.8rem;
    height: 30rem;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

@media only screen and (max-width: 893px) {

    .banner img {
        width: 17.1rem;
        height: 26rem;
    }
}

@media only screen and (max-width: 800px) {

    .banner img {
        width: 14.5rem;
        height: 22rem;
    }
}

@media only screen and (max-width: 767px) {

    .banner img {
        width: 7.8rem;
        height: 13rem;
    }

    .banner {
        flex-direction: column-reverse;
        padding: 10vh 3vw;
    }

    .banner-left-column {
        width: 80%;
        margin-top: 5%;
        margin-left: 8%;
        padding: 0;
    }

    .banner-right-column {
        width: 100%;
        margin-top: 0;
        margin-left: 0;
        padding-right: 0;
    }

    .banner .button {
        font-size: 1rem;
    }
}

@media only screen and (max-width: 490px) {

    .banner .tagline-box {
        height: 5vh;
        font-size: 1.5rem;
        display: inline-block;
    }

    .banner h1 {
        font-size: 3rem;
        font-weight: 700;
    }

    .banner h2 {
        font-size: 1.5rem;
    }

    .banner p {
        font-size: 1rem;
    }
}

/* SKILLS */
.skill {
    padding: 0 0 50px 0;
    position: relative;
    height: 100vh;
    background: linear-gradient(rgba(3, 5, 14, 0.5), #F96666, rgba(3, 5, 14, 0.5));
    background-size: cover;
}

.skill-bx {
    background-image: url('./assets/img/banner2.webp');
    border-radius: 64px;
    text-align: center;
    padding: 40px 50px;
    margin-top: 40px;
}

.skill h2 {
    font-size: 45px;
    font-weight: 700;
    margin-bottom: 50px;
}

.skill-slider {
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.lang-slider {
    margin-top: 4rem;

}

.skill-slider .item img {
    width: 40%;
    margin: 0 auto 15px auto;
}

.background-image-left {
    top: 28%;
    position: absolute;
    bottom: 0;
    width: 40%;
    z-index: -4;
}

/* PROJECTS */
.project {
    height: 100vh;
    /* padding: 20px 0; */
    display: flex;
    background-image: url("./assets/img/project-back.jpeg");
    background: linear-gradient(rgba(3, 5, 14, 0.5), rgba(49, 123, 170, 0.5), rgba(3, 5, 14, 0.5));
}

.project h2 {
    font-size: 45px;
    font-weight: 700;
    text-align: center;
    margin-top: 40px;
}

.project p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px auto 30px auto;
    text-align: center;
    width: 56%;
}

.project .nav.nav-pills {
    width: 70%;
    margin: 4% auto !important;
    border-radius: 50px;
    background-color: rgb(255 255 255 / 10%);
    overflow: hidden;
}

.project .nav.nav-pills .nav-item {
    width: 50%;
}

.project .nav.nav-pills .nav-link {
    background-color: transparent;
    border-radius: 0;
    padding: 17px 0;
    color: #fff;
    width: 100%;
    font-size: 17px;
    letter-spacing: 0.8px;
    font-weight: 500;
    position: relative;
    transition: 0.3s ease-in-out;
    text-align: center;
    z-index: 0;
}

.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90deg, #fd1a09 6%, #4A2FBD 110%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}

.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}

.project .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}

.nav-link#projects-tabs-tab-first {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 55px 0px 0px 55px;
}

.nav-link#projects-tabs-tab-second {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#projects-tabs-tab-third {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 0 55px 55px 0;
}

.proj-imgbx {
    position: relative;
    width: 80%;
    margin-left: 10%;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
}

.proj-imgbx::before {
    content: "";
    background: linear-gradient(90deg, #AA367C 6%, #4A2FBD 110%);
    opacity: 0.85;
    position: absolute;
    width: 100%;
    height: 0;
    transition: 0.4s ease-in-out;
}

.proj-imgbx:hover::before {
    height: 100%;
}

.proj-txtx {
    position: absolute;
    text-align: center;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s ease-in-out;
    opacity: 0;
    width: 100%;
}

.proj-imgbx:hover .proj-txtx {
    top: 50%;
    opacity: 1;
}

.proj-txtx h4 {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.1em;
}

.proj-txtx span {
    font-style: italic;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.8px;
}

.background-image-right {
    top: 20%;
    position: absolute;
    bottom: 0;
    width: 35%;
    right: 0;
    z-index: -4;
}

@media only screen and (max-width: 1200px) {

    .proj-txtx h4 {
        font-size: 25px;
    }

    .proj-txtx span {
        font-size: 14px;
    }
}

@media only screen and (max-width: 991px) {

    .proj-txtx h4 {
        font-size: 20px;
    }

    .proj-txtx span {
        font-size: 12px;
    }
}

@media only screen and (max-width: 575px) {

    .proj-txtx h4 {
        font-size: 25px;
    }

    .proj-txtx span {
        font-size: 14px;
    }

    .proj-imgbx {
        width: 60%;
        margin-left: 20%;
    }

}

@media only screen and (max-width: 410px) {

    .proj-txtx h4 {
        font-size: 20px;
    }

    .proj-txtx span {
        font-size: 12px;
    }

}

/* FOOTER and NEWSLETTER */

.footer {
    height: 35vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgba(3, 5, 14, 0.5), #F96666, rgba(3, 5, 14, 0.5));
}

.connect-icon-box {
    display: flex;
    justify-content: center;
}

.connect-icon-box a {
    width: 4rem;
    height: 4rem;
    margin: 2rem;
    background-color: rgb(30, 59, 79);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    line-height: 1;
    cursor: pointer;
    outline: none;
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 2px 2px 20px 10px rgba(0, 0, 0, 0.2);
}

.connect-icon-box a img {
    width: 55%;
}

.connect-icon-box a:hover {
    filter: brightness(85%);
}

.connect-icon-box a:active {
    transform: scale(.95);
}

footer h1 {
    font-size: 3rem;
}

.footer p {
    font-weight: 400;
    font-size: 1rem;
    color: #B8B8B8;
    letter-spacing: 0.5px;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 820px) {

    .connect-icon-box a {
        width: 3rem;
        height: 3rem;
    }

    .connect-icon-box a img {
        width: 45%;
    }

    footer h1 {
        font-size: 2.1rem;
    }
}

@media only screen and (max-width: 575px) {

    .connect-icon-box a {
        width: 2rem;
        height: 2rem;
    }

    .connect-icon-box a img {
        width: 35%;
    }

    footer h1 {
        font-size: 2rem;
    }
}